.headerText {
    text-align: center;
    width: 100%;
    margin-top: 6em;
    line-height: 1.5;
}

.mainHeader {
    font-size: 5rem;
    font-weight: 700;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.subHeader {
    font-size: 1.5rem;
    font-weight: 500;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.createListingButton {
    background-color: #f55e49;
    border: 0;
    font-weight: 400;
    color: #ffffff;
    margin-top: 1em;
    display: inline-flex;
    border-radius: 1.5em;
  }

  .createListingButton:hover,
  .createListingButton:active {
    color: #ffffff;
    background-color: #f14410;
  }

  @media (max-width: 760px) {
    .mainHeader {
        font-size: 3rem;
    }
}