.accountContainer {
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
    width: 70%;
    background-color: #F3F2EF;
    padding: 2em;
    
}

.accountTiles {
    display: flex !important;
    flex-wrap: wrap !important;
    width: 100%;
    margin-top: 2em;
}

 .accountCard {
  width: 30%;
  padding-left: 8px !important;
  border-radius: 24px !important;
  padding-right: 8px !important;
  box-shadow: 0 6px 20px rgba(0,0,0,0.2) !important;
  margin: 14px;
  cursor: pointer;
  background-color: #f3f2ef !important;
}

.profileCard {
  width: 100%;
  padding: 2em;
  border-radius: 24px !important;
  box-shadow: 0 6px 20px rgba(0,0,0,0.2) !important;
  background-color: #f3f2ef !important;
}

.profileContactCard {
  width: 100%;
  padding: 1em;
  border-radius: 24px !important;
  border: solid 1px #dddddd;
  margin-top: 2em;
  background-color: transparent !important;
  box-shadow: none !important;
}

.tileIcon {
  width: 10%;
  margin-bottom: 1em;
}

.tileTitle {
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-bottom: 0.5em;
  color: #000000 !important;
}

.profileTileTitle {
  font-size: 2em;
  font-weight: 700;
  color: #000000 !important;
  text-align: center;
}

.profileContactTitle {
  font-size: 22px;
  font-weight: 600;
  color: #000000 !important;
  padding: 0;
  margin-bottom: 0.5em;
}

.profileContactDetails {
  font-size: 16px;
  font-weight: 500;
  color: #000000 !important;
  padding: 0;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 1em;
}

.profileTileRole {
  font-size: 14px;
  font-weight: 500;
  color: #000000 !important;
  text-align: center;
}

.profileTileDetails {
  font-size: 1em;
  font-weight: 500;
  color: #000000 !important;
  text-align: center;
  margin-left: 4em;
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.tileSubTitle {
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-bottom: 0.5em;
}

.tileText {
  text-align: left;
}

.tileTopText {
  font-size: 22px;
  font-weight: 700;
}

.tileBotText {
  font-size: 12px;
  font-weight: 500;
}