@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@300;400;500;600;700;800&display=swap');

* {
  box-sizing: border-box;
}

html {
  font-family: 'Sarabun', sans-serif;

}

body {
  margin: 0;
  background-color: #F3F2EF;
  font-family: 'Sarabun', sans-serif !important;
}

button {
  font: inherit;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  background-color: transparent;
  color: #1a8ed1;
  border: 1px solid #1a8ed1;
}

button:hover,
button:active {
  background-color: #1ac5d1;
  border-color: #1ac5d1;
  color: white;
}