.button {
    border: 0;
    font-weight: 400;
    color: #222525;
  }
  
  .button:hover,
  .button:active {
    color: #0a8080;
    background-color: #ffffff;
  }
  
  .button span {
    margin: 0 0.5rem;
  }
  
  .badge {
    background-color: #1ad1b9;
    border-radius: 30px;
    padding: 0.15rem 1.25rem;
    color: #1d1d1d;
  }

  @media (max-width: 760px) {

    .button {
      font-size: 13px;
      background-color: #053e85;
      color: #fff;
    }
    .button span {
      margin: 0;
    }

  }
