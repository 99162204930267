.header {
    width: 100%;
    height: 5rem;
    padding: 0 6%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F3F2EF;
    border-bottom: 1px solid #ededed;
  }
  ul {
    text-decoration: none;
    list-style: none;
  }
  
  .header h1 {
    color: white;
  }
  
  .header ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .logo {
    width: 11em;
    height: 4em;
  }

  .logo img{
    width: 100%;
    height: 100%;
  }

  .leftMenu {
    width: 45%;
    display: inline-flex;
    justify-content: space-between;
  }

  .leftMenu .links {
    height: 4em;
    padding: 1.2em;
  }

  .links a {
    margin-left: 1em;
    margin-right: 1em;
    text-decoration: none;
    color: #222525;
  }

  .navbar {
    background-color: #ffffff;
  }

  .mobileHeaderClose {
    width: fit-content;
    margin-left: auto;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 7%;
  }

  .mobileHeaderImage {
    width: 30%;
  }

  .mobileHeaderImage img {
    width: 100%;
  }

  .mobileHeaderTitle {
    display: inline-flex;
    width: 100%;
  }
  

  @media (max-width: 1050px) {

  .mobileMenuSignOut {
    min-width: fit-content;
    margin-left: auto;
  }
  .mobileDropDownSignOut {
    min-width: fit-content;
    margin-left: 10%;
  }
}

@media (max-width: 760px) {

  .mobileMenuSignOut {
    min-width: fit-content;
    margin-left: auto;
  }
  .mobileDropDownSignOut {
    min-width: fit-content;
    margin-left: 10%;
  }
}