.button {
    background-color: #f55e49;
    border: 0;
    font-weight: 400;
    color: #ffffff;
    margin: 8px;
    width: 25ch;
    min-height: 3em;
  }
  
  .button:hover,
  .button:active {
    color: #ffffff;
    background-color: #f55e49;
  }
  
  .button span {
    margin: 0 0.5rem;
  }
  
  .badge {
    background-color: #1ad1b9;
    border-radius: 30px;
    padding: 0.15rem 1.25rem;
    color: #1d1d1d;
  }

  .signUpForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2em;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
  }

  .signUpForm .MuiTextField-root {
    margin: 1em;
    width: 100%;
  }

  .canButton {
    margin-left: auto;
    margin-right: auto;
  }

