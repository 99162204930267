
.containerHeader {
    font-size: 2em;
    font-weight: 500;
}

.listingsContainer {
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
    width: 65%;
    background-color: #F3F2EF;
    padding: 2em;
}

.listingTitle {
    font-weight: 500;
    text-decoration: none;
    color: #000000;
    font-size: 18px;
}

.listingCompany {
    font-weight: 300;
    font-size: 18px;
}

.listingLocation {
    font-size: 13px;
    color: #474139;
}

.listingDetails {
    font-size: 13px;
    color: #474139;
}


.filterButtons button{
    margin-right: 1em;
    border-radius: 1.59rem;
    border: 1px solid #f55e49;
    color: #f55e49;
}

.buttonActive {
    background-color: #f55e49;
    color: #ffffff !important
}

.filterButtons button:hover,
.filterButtons button:active {
  background-color: #f55e49;
  border-color: #f55e49;
  color: white;
}

.listingNavContainer {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 10px 4px rgba(0,0,0,.08);
    height: fit-content;
    display: inline-flex;
    padding: 1em;
}

.listingContractsContainer {
  width: 25%;
  background-color: #ffffff;
  padding: 2em;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 0px 0px 1px #e7dcdc;
  margin-right: 5em;
  height: 30em;
}

.listingDetailsContainer {
  width: 100%;
  margin-top: 5em;
  margin-bottom: 5em;
}

.listingContractContainer {
  width: 65%;
  padding: 2em;
  margin-left: auto;
  margin-right: auto;
}

.contractsContainer {
  width: 65%;
  padding: 2em;
  margin-left: auto;
  margin-right: auto;
}

.candidatesContainer {
  width: 65%;
  padding: 2em;
  margin-left: auto;
  margin-right: auto;
}

.listingEditContainer {
  width: 65%;
  margin-left: auto;
  margin-right: auto;
  background-color: #F3F2EF;
  padding: 2em;
  border: none;
}

.jobDescriptionTitle {
    font-size: 2em;
    font-weight: 300;
    margin-bottom: 1em;
}

.jobCardDetails {
    width: 60%;
    margin: auto;
    padding: 2em;
    margin-top: 3em;
}

.listingCard {
  margin-top: 2em;
  display: inline-flex;
  width: 100%;
  padding: 1em;
  transition: 100ms linear;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px 4px rgba(0,0,0,.08);
}

.listingCard:hover {
  box-shadow: 0 4px 10px 4px rgba(0,0,0,.08);
  cursor: pointer;
  transform: scale(1.03);
}

.listingCardDetails {
    display:inline-flex;
    width:100%;
    margin-bottom: 2em;
}

.pipelineCard {
  margin-bottom: 2em;
  display: inline-flex;
  width: 100%;
  padding: 1em;
  transition: 100ms linear;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px 4px rgba(0,0,0,.08);
}

.pipelineCard:hover {
  box-shadow: 0 4px 10px 4px rgba(0,0,0,.08);
  cursor: pointer;
  transform: scale(1.03);
}

.pipelineCardDetails {
    display:inline-flex;
    width:100%;
    margin-bottom: 2em;
}

.contractCard {
  margin-top: 2em;
  display: inline-flex;
  width: 50%;
  padding: 1em;
  transition: 100ms linear;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px 4px rgba(0,0,0,.08);
  margin-right: 3em;
}

.contractCard:hover {
  box-shadow: 0 4px 10px 4px rgba(0,0,0,.08);
  cursor: pointer;
  transform: scale(1.03);
}

.contractCardDetails {
    display:inline-flex;
    width:100%;
    margin-bottom: 2em;
}

.jobSubTitle {
    font-size: 1em;
    margin-top: 1em;
    font-weight: 700;
    margin-bottom: 1em;
}

.bubbleListContainer {
    font-size: 1em;
    margin-top: 1em;
    font-weight: 700;
    margin-bottom: 1em;
}

.bubbleListContainer button {
    border:none;
    color: #ffffff;

}

.bubbleListContainer button span{
    margin: 0;

}

.bubbleListContainer  button:hover,
button:active {
  background-color: transparent;
}

.jobDescription a{
    text-decoration: none;
}

.listingButtonGroup button{
  width: 30%;
  margin-right: 2em;
}

.button {
    background-color: #f55e49;
    border: 0;
    font-weight: 400;
    color: #ffffff;
    width: 100%;
    min-height: 3em;
    margin-top: 1em;
  }

  .detailsSpans {
    background-color: #f55e49;
    border: 0;
    font-weight: 500;
    color: #ffffff;
    width: fit-content;
    min-height: 2em;
    margin-top: 1em;
    border-radius: 20px;
    padding: 3px;
    font-size: 15px;
    margin-right: 1em;
  }

  .detailsSpans span {
    margin: 0 0.9rem;
  }

  .bubbleList {
    background-color: #f55e49;
    border: 0;
    font-weight: 500;
    color: #ffffff;
    width: fit-content;
    min-height: 2em;
    margin-top: 1em;
    border-radius: 20px;
    padding: 3px;
    font-size: 15px;
    margin-right: 1em;
  }

  .bubbleList span {
    margin: 0 0.9rem;
  }

  .button:hover,
  .button:active {
    color: #ffffff;
    background-color: #f14410;
  }
  
  .button span {
    margin: 0 0.5rem;
  }

  .listingFormStepOne {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4em;
    margin-bottom: 4em;
    width: 35%;
  }

  .listingFormStepOne .listingFormFullField {
    margin-bottom: 1em;
    width: 100%;
  }

  .listingFormStepOne .listingFormHalfField {

    width: 50%;
  }

  .formFieldGroup {
    display: inline-flex;
    width: 100%;
    margin-bottom: 1em;
  }

  .editDescriptionInput {
    width:80%;
  }

  .editEditDescriptionInput {
    width:100%;
  }

  .addRespInput {
    width: 50%;
  }

  .editNewListingContainer {
    display: inline-flex;
    width: 100%;
  }

  .addRespButton {
    background-color: #f55e49;
    border: 0;
    font-weight: 400;
    color: #ffffff;
    min-width: 10%;
    min-height: 3em;
    margin-left: 1em;
  }

  .addRespButton:hover,
  .addRespButton:active {
    color: #ffffff;
    background-color: #f14410;
  }

  .addSalaryInput {
    width: 20%;
    margin-right: 1em !important;
  }

  .updateListingButton {
    background-color: #f55e49;
    border: 0;
    font-weight: 400;
    color: #ffffff;
    min-width: 20%;
    min-height: 3em;
    margin-top: 2em;
    display: block;
  }

  .updateListingButton:hover,
  .updateListingButton:active {
    color: #ffffff;
    background-color: #f14410;
  }

.editListContainer {
    margin-top: 2em;
}

.editListingFormFullField {
    width: 30%;
    margin-right: 1em !important;
}

.createListingButton {
    background-color: #f55e49;
    border: 0;
    font-weight: 400;
    color: #ffffff;
    margin-top: 1em;
    display: inline-flex;
    margin-left: 75%;
  }

  .createListingButton:hover,
  .createListingButton:active {
    color: #ffffff;
    background-color: #f14410;
  }

.listingButtonGroup {
    width: 100%;
    display: inline-flex;
}

.navList {
    display: inline-flex;
    width: 100%;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    color: #4b4848;
    transition: 100ms linear;
}

.navList :hover{
  transform: scale(1.1);
}

.navList a{
    margin: 0 0.5em;
}

.dropPipeline {
  margin-left: 5px;
  border: black 2px solid;
  border-radius: 5px;
  padding: 3px;
  cursor: pointer;
}

.dropContract {
  border: black 2px solid;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

.paginationNav {
  margin-left: auto;
  margin-right: auto;
}

.pipelineStatus {
  background-color: #629be6;
  border: 0;
  font-weight: 500;
  color: #ffffff;
  width: -moz-fit-content;
  width: fit-content;
  min-height: 2em;
  margin-top: 5px;
  border-radius: 15px;
  padding: 6px;
  font-size: 10px;
  margin-right: 1em;
  min-width: 4em;
}

.contractDetailsCard {
  width: 100%;
  display: inline-flex;
}

.cardRecruiterDetails {
  width: 40%;
  padding: 1em;
  transition: 100ms linear;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px 4px rgba(0,0,0,.08);
  height: fit-content;
  display: block;
}

.cardRecruiterTitle {
  display: inline-flex;
}

.cardRecruiterName {
  margin-left: 1em;
}

.cardRecruiterContact {
  margin-top: 2em;
}

.cardPipelines {
  width: 55%;
  margin-left: 2em;
}

.ql-editor {
  height: 100%;
}

.mobileFilter  {
  display: none;
}



@media (max-width: 1050px) {
  .listingsContainer {
    width: 90%;
  }
  .listingFormStepOne {
    width: 60%;
  }
  .contractCard {
    width: 70%;
  }
  .cardRecruiterDetails {
    width: 100%;
  }
  .contractDetailsCard { 
    width: 70%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .cardPipelines {
    margin-top: 2em;
    width: 100%;
    margin-left: 0;
  }
}


@media (max-width: 760px) {
  .listingsContainer {
    width: 90%;
  }
  .listingsContainer .listingNavContainer .listingTitle {
    display: none;
  }
  .filterButtons {
    font-size: 10px;
    min-width: 25%;
  }
  .createListingButton {
    margin-left: 50%;
  }
  .listingFormStepOne {
    width: 80%;
  }
  .listingButtonGroup {
    display: block;
  }
  .listingButtonGroup button{
    width: 80%;
  }
  .addSalaryInput {
    width: 45%;
  }
  .editListingFormFullField {
    width: 40%;
    margin: 0;
  }
  .contractCard {
    width: 90%;
  }
  .cardRecruiterDetails {
    width: 100%;
  }
  .contractDetailsCard { 
    width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .cardPipelines {
    margin-top: 2em;
    width: 100%;
    margin-left: 0;
  }
  .mobileFilter  {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-top: 1em;
  }

  .filterButtons {
    display: none;
    
  }
}