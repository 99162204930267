
.title {
    font-size: 3em;
    font-weight: 800;
}
  
.subtitle {
    font-size: 1em;
    font-weight: 500;

}

.profileDetails {
    margin-left: 1em;
    margin-right: 1em;
}

.profileInputs {
    width: 60%;
    margin-top: 2em !important;
    border-radius: 8px;
}

.button {
    background-color: #f55e49;
    border: 0;
    font-weight: 400;
    color: #ffffff;
    margin-top: 2em;
    width: 25ch;
    min-height: 3em;
    display: block;
  }
  
  .button:hover,
  .button:active {
    color: #ffffff;
    background-color: #f55e49;
  }
  
  .button span {
    margin: 0 0.5rem;
  }

  .accountContainer {
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
    width: 70%;
    background-color: #F3F2EF;
    padding: 2em;
    
}

.accountTiles {
    display: flex !important;
    flex-wrap: wrap !important;
    width: 100%;
    margin-top: 2em;
}

 .accountCard {
  width: 30%;
  padding-left: 8px !important;
  border-radius: 12px !important;
  padding-right: 8px !important;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px !important;
  margin: 8px;
}

.tileIcon {
  width: 10%;
  margin-bottom: 1em;
}

.tileTitle {
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-bottom: 0.5em;
  color: #000000 !important;
}

.tileSubTitle {
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-bottom: 0.5em;
}

.publicProfileName {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 1em;
}

.publicProfileReviewTitle {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 1em;
}

.publicProfileDetails {
  font-size: 16px;
  font-weight: 400;
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  gap: 14px;
}

.publicProfileReview {
  font-size: 16px;
  font-weight: 400;
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  gap: 14px;
  padding: 1em;
  border-radius: 24px !important;
  border: solid 1px #dddddd;
}
 

@media (max-width: 1050px) {
  .listingsContainer {
    width: 90%;
  }
}

@media (max-width: 760px) {
  .listingsContainer {
    width: 90%;
  }
}