
.title {
    font-size: 3em;
    font-weight: 800;
}
  
.subtitle {
    font-size: 1em;
    font-weight: 500;

}

.profileDetails {
    margin-left: 1em;
    margin-right: 1em;
}

.profileInputs {
    width: 60%;
    margin-top: 2em !important;
}

.button {
    background-color: #f55e49;
    border: 0;
    font-weight: 400;
    color: #ffffff;
    margin-top: 2em;
    width: 25ch;
    min-height: 3em;
    display: block;
  }
  
  .button:hover,
  .button:active {
    color: #ffffff;
    background-color: #f55e49;
  }
  
  .button span {
    margin: 0 0.5rem;
  }

.appActive {
  display:block
}

.appInactive {
  display:none
}

.canButton {
  margin-left: auto;
  margin-right: auto;
}