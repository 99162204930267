
.title {
    font-size: 3em;
    font-weight: 800;
}
  
.subtitle {
    font-size: 1em;
    font-weight: 500;

}

.profileDetails {
    margin-left: 1em;
    margin-right: 1em;
}

.profileInputs {
    width: 60%;
    margin-top: 2em !important;
}

.button {
    background-color: #f55e49;
    border: 0;
    font-weight: 400;
    color: #ffffff;
    margin-top: 2em;
    width: 25ch;
    min-height: 3em;
    display: block;
  }
  
  .button:hover,
  .button:active {
    color: #ffffff;
    background-color: #f55e49;
  }
  
  .button span {
    margin: 0 0.5rem;
  }

.cardRecruiterDetails {
  padding: 1em;
  transition: 100ms linear;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px 4px rgba(0,0,0,.08);
  height: fit-content;
  display: inline-flex;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}

.cardRecruiterTitle {
  display: inline-flex;
}

.cardRecruiterName {
  margin-left: 1em;
}

.cardRecruiterContact {
  /* margin-top: 2em; */
}

.cardPipelines {
  width: 55%;
  margin-left: 2em;
}

.cardPipelineDetails {
    display: inline-flex;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    padding: 1em;
}

.stepperLabel {
    font-weight: 700;
}

.addRespButton {
    background-color: #053e85;
    border: 0;
    font-weight: 400;
    color: #ffffff;
    min-width: 10%;
    min-height: 3em;
    margin-left: 1em;
  }

  .addRespButton:hover,
  .addRespButton:active {
    color: #ffffff;
    background-color: #195196;
  }

.pipelineButton {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
}

.listingsContainer {
  margin-left: auto;
  margin-right: auto;
  margin-top: 3em;
  width: 100%;
  display: inline-flex;
}

.pipelineProgress {
  margin-left:"5em";
  margin-top: auto;
  margin-bottom: auto;
}


@media (max-width: 1050px) {
  .cardRecruiterDetails {
    width: 90%;
  }
}


@media (max-width: 760px) {
  .listingsContainer {
    width: 100%;
  }
  .cardRecruiterDetails {
    width: 100%;
    border: none;
    box-shadow: none;
    padding: 0;
  }
  .cardPipelineDetails {
    width: 100%;
    display: block;
    padding: 0;
  }
  .candidatePipelineCard {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
  .pipelineProgress {
    width: '100%';
    margin-left:0;
    margin-top: 5em;
  }
  .addRespButton {
    margin-left: 0;
  }
}