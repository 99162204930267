.button {
    background-color: #f55e49;
    border: 0;
    font-weight: 400;
    color: #ffffff;
    margin-left: 1em;
  }
  
  .button:hover,
  .button:active {
    color: #ffffff;
    background-color: #f55e49;
  }
  
  .button span {
    margin: 0 0.5rem;
  }
  
  .badge {
    background-color: #1ad1b9;
    border-radius: 30px;
    padding: 0.15rem 1.25rem;
    color: #1d1d1d;
  }

  @media (max-width: 1050px) {

    .button {
      min-width: 14em;
      width: fit-content;
      font-size: 20px;
    }
  }

  @media (max-width: 760px) {

    .button {
      min-width: 8em;
    }
    .button span {
      margin: 0;
      font-size: 15px;
    }

  }